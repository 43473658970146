import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line max-len
import { addInterventType, setInterventType, upsertInterventType, addInterventTypes, upsertInterventTypes, updateInterventType, updateInterventTypes, mapInterventType, mapInterventTypes, deleteInterventType, deleteInterventTypes, deleteInterventTypesByPredicate, loadInterventTypes, setInterventTypes, clearInterventTypes, syncInterventTypes, syncInterventTypesSuccess, syncInterventTypesFail } from './interventTypes.actions';
import { interventTypesAdapter, InterventTypesState } from './InterventTypesState';

/**
 * The initial state for the interventTypes
 */
const initialState: InterventTypesState = interventTypesAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the projects state
 */
const reducer = createReducer(
  initialState,
  on(addInterventType, (state, { interventType }) => interventTypesAdapter.addOne(interventType, state)),
  on(setInterventType, (state, { interventType }) => interventTypesAdapter.setOne(interventType, state)),
  on(upsertInterventType, (state, { interventType }) => interventTypesAdapter.upsertOne(interventType, state)),
  on(addInterventTypes, (state, { interventTypes }) => interventTypesAdapter.addMany(interventTypes, state)),
  on(upsertInterventTypes, (state, { interventTypes }) => interventTypesAdapter.upsertMany(interventTypes, state)),
  on(updateInterventType, (state, { update }) => interventTypesAdapter.updateOne(update, state)),
  on(updateInterventTypes, (state, { updates }) => interventTypesAdapter.updateMany(updates, state)),
  on(mapInterventType, (state, { entityMap }) => interventTypesAdapter.mapOne(entityMap, state)),
  on(mapInterventTypes, (state, { entityMap }) => interventTypesAdapter.map(entityMap, state)),
  on(deleteInterventType, (state, { id }) => interventTypesAdapter.removeOne(id, state)),
  on(deleteInterventTypes, (state, { ids }) => interventTypesAdapter.removeMany(ids, state)),
  on(deleteInterventTypesByPredicate, (state, { predicate }) => interventTypesAdapter.removeMany(predicate, state)),
  on(loadInterventTypes, (state, { interventTypes }) => interventTypesAdapter.setAll(interventTypes, state)),
  on(setInterventTypes, (state, { interventTypes }) => interventTypesAdapter.setMany(interventTypes, state)),
  on(clearInterventTypes, state => interventTypesAdapter.removeAll({ ...state, selectedUserId: null })),

  on(syncInterventTypes, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(syncInterventTypesSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(syncInterventTypesFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),
);

/**
 * The intervent types reducer
 */
export const interventTypesReducer = (state: InterventTypesState, action: Action) => reducer(state, action);
