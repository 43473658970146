import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { ModalStatusComponent } from './modal-status.component';
import { ButtonModule } from '../button/button.module';
import { DropDownModule } from '../drop-down/drop-down.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DropDownModule,
    ButtonModule
  ],
  exports: [
    ModalStatusComponent
  ],
  declarations: [
    ModalStatusComponent
  ]
})
export class ModalStatusModule { }
