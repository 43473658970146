import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line max-len
import { addProject, addProjects, clearProjects, deleteProject, deleteProjects, deleteProjectsByPredicate, loadProjects, mapProject, mapProjects, setProject, setProjects, syncProjects, syncProjectsFail, syncProjectsSuccess, updateProject, updateProjects, upsertProject, upsertProjects } from './projects.actions';
import { projectsAdapter, ProjectsState } from './ProjectsState';

/**
 * The initial state for the projects
 */
const initialState: ProjectsState = projectsAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the projects state
 */
const reducer = createReducer(
  initialState,
  on(addProject, (state, { project }) => projectsAdapter.addOne(project, state)),
  on(setProject, (state, { project }) => projectsAdapter.setOne(project, state)),
  on(upsertProject, (state, { project }) => projectsAdapter.upsertOne(project, state)),
  on(addProjects, (state, { projects }) => projectsAdapter.addMany(projects, state)),
  on(upsertProjects, (state, { projects }) => projectsAdapter.upsertMany(projects, state)),
  on(updateProject, (state, { update }) => projectsAdapter.updateOne(update, state)),
  on(updateProjects, (state, { updates }) => projectsAdapter.updateMany(updates, state)),
  on(mapProject, (state, { entityMap }) => projectsAdapter.mapOne(entityMap, state)),
  on(mapProjects, (state, { entityMap }) => projectsAdapter.map(entityMap, state)),
  on(deleteProject, (state, { id }) => projectsAdapter.removeOne(id, state)),
  on(deleteProjects, (state, { ids }) => projectsAdapter.removeMany(ids, state)),
  on(deleteProjectsByPredicate, (state, { predicate }) => projectsAdapter.removeMany(predicate, state)),
  on(loadProjects, (state, { projects }) => projectsAdapter.setAll(projects, state)),
  on(setProjects, (state, { projects }) => projectsAdapter.setMany(projects, state)),
  on(clearProjects, state => projectsAdapter.removeAll({ ...state, selectedUserId: null })),

  on(syncProjects, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(syncProjectsSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(syncProjectsFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),
);

/**
 * The projects reducer
 */
export const projectsReducer = (state: ProjectsState, action: Action) => reducer(state, action);
