import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/states/AppState';
import { environment } from 'src/environments/environment';
import { JsonApiService } from '../jsonapi/jsonapi.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private store: Store<AppState>,
    private api: JsonApiService
  ) { }

  getInstance(): Observable<string> {
    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn && loginState.instance) {
          return of(loginState.instance);
        }
        return of('');
      })
    );
  }

  public buildApiBase() {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance().pipe(take(1)).subscribe((instance) => {
          let apiBase = environment.data.baseUrl + '/' + 'auth';
          if (instance && instance.length) {
            apiBase = '';
            const instanceParams = environment.multiInstance[instance];
            if (instanceParams) {
              apiBase = instanceParams.data.baseUrl + '/' + 'auth';
            }
          }
          resolve(apiBase);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getUser() {
    const apiBase = await this.buildApiBase();
    return new Observable<any>(observer => {
      const route = `${apiBase}/user`;
      this.api.customFullRouteGetRequest(route).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const user = (response.data) ? response.data : null;
          observer.next(user);
        });
      });
    });
  }

}
