import { NgModule } from '@angular/core';
import { OrderByPipe } from './orderBy.pipe';

@NgModule({
  declarations: [OrderByPipe],
  imports: [],
  exports: [OrderByPipe],
})

export class OrderByPipeModule { }
