import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface ComponentInteraction {
  remove(index: number);
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set title(val: string) {
    this.itemTitle = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set prefixIcon(val: string) {
    this.itemPrefixIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set suffixIcon(val: string) {
    this.itemSuffixIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set prefixSrcIcon(val: string) {
    this.itemPrefixSrcIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set suffixSrcIcon(val: string) {
    this.itemSuffixSrcIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set theme(val: string) {
    this.itemTheme = (val !== undefined && val !== null) ? val : 'primary';
  }

  @Input() set disabled(val: boolean) {
    this.itemIsDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set fluid(val: boolean) {
    this.itemIsFluid = (val !== undefined && val !== null) ? val : false;
  }

  @Output() itemClick = new EventEmitter();

  public index: number;
  public name: string;
  public selfRef: ButtonComponent;
  //interface for Parent-Child interaction
  public compInteraction: ComponentInteraction;

  public itemLabel: string;
  public itemTitle: string;
  public itemPrefixIcon: string;
  public itemSuffixIcon: string;
  public itemPrefixSrcIcon: string;
  public itemSuffixSrcIcon: string;
  public itemTheme: string;
  public itemIsDisabled: boolean;
  public itemIsFluid: boolean;

  constructor() {
    this.itemIsDisabled = false;
    this.itemIsFluid = false;
  }

  ngOnChanges() {
  }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index);
    }
  }

  onItemClick(event) {
    // console.log('itemClick', event);
    this.itemClick.emit();
  }
}
