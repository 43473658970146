import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/states/AppState';
import { logout, refreshToken } from 'src/app/states/login/login.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  canLoad(): Observable<boolean> {
    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn) {
          return of(true);
        }
        this.router.navigateByUrl('login');
        return of(false);
      })
    );
  }
}
