import { Action, createReducer, on } from '@ngrx/store';
import { appInitialState } from '../AppInitialState';
import { addTask } from './task.actions';
import { TaskState } from './TaskState';

/**
 * The initial state for the task
 */
const initialState: TaskState = appInitialState.task;

/**
 * The reducer for the task state
 */
const reducer = createReducer(
  initialState,
  on(addTask, (currentState) => ({
    ...currentState,
    isAdding: true,
    isAdded: false,
    item: null
  }))
);

/**
 * The task reducer
 */
export const taskReducer = (state: TaskState, action: Action) => reducer(state, action);
