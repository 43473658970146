import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {
  transform(items: any[], callback: (itemA: any, itemB: any, args: any) => number, args: any): any {
    if (!items || !callback) {
      return items;
    }
    return items.sort((a, b) => callback(a, b, args));
  }
}
