import { createAction } from '@ngrx/store';

/**
 * The show loading action
 */
export const show = createAction('[Loading] show');

/**
 * The hide loading action
 */
export const hide = createAction('[Loading] hide');
