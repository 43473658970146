import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line max-len
import { addTask, setTask, upsertTask, addTasks, upsertTasks, updateTask, updateTasks, mapTask, mapTasks, deleteTask, deleteTasks, deleteTasksByPredicate, loadTasks, setTasks, clearTasks, syncTasks, syncTasksFail, syncTasksSuccess, syncTask, syncTaskFail, syncTaskSuccess, syncProjectTasks, syncProjectTasksFail, syncProjectTasksSuccess } from './tasks.actions';
import { tasksAdapter, TasksState } from './TasksState';

/**
 * The initial state for the tasks
 */
const initialState: TasksState = tasksAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the Tasks state
 */
const reducer = createReducer(
  initialState,
  on(addTask, (state, { task }) => tasksAdapter.addOne(task, state)),
  on(setTask, (state, { task }) => tasksAdapter.setOne(task, state)),
  on(upsertTask, (state, { task }) => tasksAdapter.upsertOne(task, state)),
  on(addTasks, (state, { tasks }) => tasksAdapter.addMany(tasks, state)),
  on(upsertTasks, (state, { tasks }) => tasksAdapter.upsertMany(tasks, state)),
  on(updateTask, (state, { update }) => tasksAdapter.updateOne(update, state)),
  on(updateTasks, (state, { updates }) => tasksAdapter.updateMany(updates, state)),
  on(mapTask, (state, { entityMap }) => tasksAdapter.mapOne(entityMap, state)),
  on(mapTasks, (state, { entityMap }) => tasksAdapter.map(entityMap, state)),
  on(deleteTask, (state, { id }) => tasksAdapter.removeOne(id, state)),
  on(deleteTasks, (state, { ids }) => tasksAdapter.removeMany(ids, state)),
  on(deleteTasksByPredicate, (state, { predicate }) => tasksAdapter.removeMany(predicate, state)),
  on(loadTasks, (state, { tasks }) => tasksAdapter.setAll(tasks, state)),
  on(setTasks, (state, { tasks }) => tasksAdapter.setMany(tasks, state)),
  on(clearTasks, state => tasksAdapter.removeAll({ ...state, selectedUserId: null })),

  on(syncTasks, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(syncTasksSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(syncTasksFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),

  on(syncTask, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(syncTaskSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(syncTaskFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),

  on(syncProjectTasks, (currentState, action) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(syncProjectTasksSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(syncProjectTasksFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),

);

/**
 * The tasks reducer
 */
export const tasksReducer = (state: TasksState, action: Action) => reducer(state, action);
