import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { ModalFormComponent } from './modal-form.component';
import { ButtonModule } from '../button/button.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DropDownModule,
    ButtonModule
  ],
  exports: [
    ModalFormComponent
  ],
  declarations: [
    ModalFormComponent
  ]
})
export class ModalFormModule { }
