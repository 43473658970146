import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InterventType } from 'src/app/models/interventType/InterventType';
import { DataService } from 'src/app/services/data/data.service';
import { logout } from '../login/login.actions';
import { loadInterventTypes, syncInterventTypes, syncInterventTypesFail, syncInterventTypesSuccess } from './interventTypes.actions';

@Injectable()
export class InterventTypesEffects {

  syncInterventTypes$ = createEffect(() => this.actions$.pipe(
    ofType(syncInterventTypes),
    switchMap(() => this.dataService.getInterventTypes().pipe(
      map((interventTypes) => syncInterventTypesSuccess({ interventTypes })),
      catchError(error => of(syncInterventTypesFail({ error })))
    ))
  ));

  syncInterventTypesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(syncInterventTypesSuccess),
    map((payload: { interventTypes: InterventType[] }) => loadInterventTypes(payload))
  ));

  // syncInterventTypesFail$ = createEffect(() => this.actions$.pipe(
  //   ofType(syncInterventTypesFail),
  //   map(() => logout())
  // ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
