import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line max-len
import { addImage, addImages, clearImages, deleteImage, deleteImages, deleteImagesByPredicate, loadImages, mapImage, mapImages, setImage, setImages, syncImage, syncImageFail, syncImageSuccess, updateImage, updateImages, upsertImage, upsertImages } from './images.actions';
import { imagesAdapter, ImagesState } from './ImagesState';

/**
 * The initial state for the sections
 */
const initialState: ImagesState = imagesAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the images state
 */
const reducer = createReducer(
  initialState,
  on(addImage, (state, { image }) => imagesAdapter.addOne(image, state)),
  on(setImage, (state, { image }) => imagesAdapter.setOne(image, state)),
  on(upsertImage, (state, { image }) => imagesAdapter.upsertOne(image, state)),
  on(addImages, (state, { images }) => imagesAdapter.addMany(images, state)),
  on(upsertImages, (state, { images }) => imagesAdapter.upsertMany(images, state)),
  on(updateImage, (state, { update }) => imagesAdapter.updateOne(update, state)),
  on(updateImages, (state, { updates }) => imagesAdapter.updateMany(updates, state)),
  on(mapImage, (state, { entityMap }) => imagesAdapter.mapOne(entityMap, state)),
  on(mapImages, (state, { entityMap }) => imagesAdapter.map(entityMap, state)),
  on(deleteImage, (state, { id }) => imagesAdapter.removeOne(id, state)),
  on(deleteImages, (state, { ids }) => imagesAdapter.removeMany(ids, state)),
  on(deleteImagesByPredicate, (state, { predicate }) => imagesAdapter.removeMany(predicate, state)),
  on(loadImages, (state, { images }) => imagesAdapter.setAll(images, state)),
  on(setImages, (state, { images }) => imagesAdapter.setMany(images, state)),
  on(clearImages, state => imagesAdapter.removeAll({ ...state, selectedUserId: null })),

  on(syncImage, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(syncImageSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(syncImageFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),
);

/**
 * The images reducer
 */
export const imagesReducer = (state: ImagesState, action: Action) => reducer(state, action);
