import { createAction, props } from '@ngrx/store';
import { Task } from 'src/app/models/task/Task';

/**
 * The add task action
 */
export const addTask = createAction('[Add task]', props<{ projectId: string }>());

/**
 * The add task success action
 */
export const addTaskSuccess = createAction('[Add task] success', props<{ task: Task }>());

/**
 * The add task fail action
 */
export const addTaskFail = createAction('[Add task] fail', props<{ error: any }>());

/**
 * The update task action
 */
export const update = createAction('[Task update]');

/**
 * The remove task action
 */
export const remove = createAction('[Task remove]');
