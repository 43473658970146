import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line max-len
import { addDraft, addDrafts, clearDrafts, deleteDraft, deleteDrafts, deleteDraftsByPredicate, loadDrafts, mapDraft, mapDrafts, sendDraft, sendDraftFail, sendDrafts, sendDraftsFail, sendDraftsSuccess, sendDraftSuccess, setDraft, setDrafts, updateDraft, updateDrafts, upsertDraft, upsertDrafts } from './drafts.actions';
import { draftsAdapter, DraftsState } from './DraftsState';

/**
 * The initial state for the drafts
 */
const initialState: DraftsState = draftsAdapter.getInitialState({
  error: null,
  isSending: false,
  isSended: false
});

/**
 * The reducer for the Drafts state
 */
const reducer = createReducer(
  initialState,
  on(addDraft, (state, { task }) => draftsAdapter.addOne(task, state)),
  on(setDraft, (state, { task }) => draftsAdapter.setOne(task, state)),
  on(upsertDraft, (state, { task }) => draftsAdapter.upsertOne(task, state)),
  on(addDrafts, (state, { tasks }) => draftsAdapter.addMany(tasks, state)),
  on(upsertDrafts, (state, { tasks }) => draftsAdapter.upsertMany(tasks, state)),
  on(updateDraft, (state, { update }) => draftsAdapter.updateOne(update, state)),
  on(updateDrafts, (state, { updates }) => draftsAdapter.updateMany(updates, state)),
  on(mapDraft, (state, { entityMap }) => draftsAdapter.mapOne(entityMap, state)),
  on(mapDrafts, (state, { entityMap }) => draftsAdapter.map(entityMap, state)),
  on(deleteDraft, (state, { id }) => draftsAdapter.removeOne(id, state)),
  on(deleteDrafts, (state, { ids }) => draftsAdapter.removeMany(ids, state)),
  on(deleteDraftsByPredicate, (state, { predicate }) => draftsAdapter.removeMany(predicate, state)),
  on(loadDrafts, (state, { tasks }) => draftsAdapter.setAll(tasks, state)),
  on(setDrafts, (state, { tasks }) => draftsAdapter.setMany(tasks, state)),
  on(clearDrafts, state => draftsAdapter.removeAll({ ...state, selectedUserId: null })),

  on(sendDraft, (currentState) => ({
    ...currentState,
    error: null,
    isSending: true,
    isSended: false
  })),
  on(sendDraftSuccess, (currentState) => ({
    ...currentState,
    isSending: false,
    isSended: true
  })),
  on(sendDraftFail, (currentState, { error }) => ({
    ...currentState,
    error,
    isSending: false,
    isSended: false
  })),

  on(sendDrafts, (currentState) => ({
    ...currentState,
    error: null,
    isSending: true,
    isSended: false
  })),
  on(sendDraftsSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isSending: false,
    isSended: true
  })),
  on(sendDraftsFail, (currentState, { error }) => ({
    ...currentState,
    error,
    isSending: false,
    isSended: false
  })),

);

/**
 * The tasks reducer
 */
export const draftsReducer = (state: DraftsState, action: Action) => reducer(state, action);
