import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';
import { Utilities } from 'src/app/commons/utilities.class';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-status',
  templateUrl: './modal-status.component.html',
  styleUrls: ['./modal-status.component.scss'],
})
export class ModalStatusComponent implements OnInit {
  public task: any = null;
  public status: string;
  public taskStatuses: any[] = [];
  public taskCircleIcons: any[];
  public message = '';
  hasPreviuosStatusFuncionality: boolean;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.task = this.navParams.get('task');
    this.status = (this.task.status) ? this.task.status : '';
    this.taskCircleIcons = [];

    this.taskStatuses = environment.features.task.flowStatuses;
    this.taskStatuses.forEach(element => {
      this.taskCircleIcons[element.key] = Utilities.getTaskIconPath('circle', element.key, element.isOpen);
    });
    this.hasPreviuosStatusFuncionality = environment.features.task.hasPreviuosStatusFuncionality;
  }

  ngOnInit() {
    // console.log(this.task);
  }

  async dismiss(data: any) {
    await this.modalController.dismiss({
      dismissed: true,
      taskId: this.task.id,
      status: this.status,
      data
    });
  }

  changeTaskStatus() {
    // console.log(this.message);
    // console.log('task ID', this.task.id);
    this.dismiss({
      confirmed: true,
      status: this.status,
      message: this.message,
      task: this.task,
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  onStatusClick(status: { key: string }) {
    this.status = status.key;
  }

  canBeNext(current: any, nextCandidate: any) {
    const indexCurrentStatus = _.findIndex(this.taskStatuses, (o: { key: any }) => o.key === current);
    if (indexCurrentStatus < 0) {
      return false;
    }
    return ((indexCurrentStatus) && (_.indexOf(this.taskStatuses[indexCurrentStatus].nextKeys, nextCandidate) !== -1)) ? true : false;
  }

  canBePrevious(current: any, previousCandidate: any) {
    const indexCurrentStatus = _.findIndex(this.taskStatuses, (o: { key: any }) => o.key === current);
    if (indexCurrentStatus < 0) {
      return false;
    }
    return (
      (indexCurrentStatus) &&
      (_.indexOf(this.taskStatuses[indexCurrentStatus].previousKeys, previousCandidate) !== -1)
    ) ? true : false;
  }
}
