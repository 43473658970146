import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { ModalInterventTypeComponent } from './modal-intervent-type.component';
import { FormsModule } from '@angular/forms';
import { OrderByPipeModule } from 'src/app/pipes/orderBy.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    OrderByPipeModule
  ],
  exports: [
    ModalInterventTypeComponent
  ],
  declarations: [
    ModalInterventTypeComponent
  ]
})
export class ModalInterventTypeModule { }
