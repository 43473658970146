import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/states/AppState';
import { logout, refreshToken } from 'src/app/states/login/login.actions';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  canLoad(): Observable<boolean> {
    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn) {
          if (loginState.user.token.refreshToken) {
            this.store.select('network').pipe(
              take(1)
            ).subscribe(networkState => {
              if (networkState.connected) {
                const token = loginState.user.token.refreshToken;
                this.store.dispatch(refreshToken({ refreshToken: token }));
                return of(true);
              }
              else {
                return of(true);
              }
            });
          }
          else {
            this.store.dispatch(logout());
            return of(true);
          }
        }
        return of(true);
      })
    );
  }
}
