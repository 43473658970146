import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line max-len
import { addSection, setSection, upsertSection, addSections, upsertSections, updateSection, updateSections, mapSection, mapSections, deleteSection, deleteSections, deleteSectionsByPredicate, loadSections, setSections, clearSections, syncSections, syncSectionsSuccess, syncSectionsFail } from './sections.actions';
import { sectionsAdapter, SectionsState } from './SectionsState';

/**
 * The initial state for the sections
 */
const initialState: SectionsState = sectionsAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the projects state
 */
const reducer = createReducer(
  initialState,
  on(addSection, (state, { section }) => sectionsAdapter.addOne(section, state)),
  on(setSection, (state, { section }) => sectionsAdapter.setOne(section, state)),
  on(upsertSection, (state, { section }) => sectionsAdapter.upsertOne(section, state)),
  on(addSections, (state, { sections }) => sectionsAdapter.addMany(sections, state)),
  on(upsertSections, (state, { sections }) => sectionsAdapter.upsertMany(sections, state)),
  on(updateSection, (state, { update }) => sectionsAdapter.updateOne(update, state)),
  on(updateSections, (state, { updates }) => sectionsAdapter.updateMany(updates, state)),
  on(mapSection, (state, { entityMap }) => sectionsAdapter.mapOne(entityMap, state)),
  on(mapSections, (state, { entityMap }) => sectionsAdapter.map(entityMap, state)),
  on(deleteSection, (state, { id }) => sectionsAdapter.removeOne(id, state)),
  on(deleteSections, (state, { ids }) => sectionsAdapter.removeMany(ids, state)),
  on(deleteSectionsByPredicate, (state, { predicate }) => sectionsAdapter.removeMany(predicate, state)),
  on(loadSections, (state, { sections }) => sectionsAdapter.setAll(sections, state)),
  on(setSections, (state, { sections }) => sectionsAdapter.setMany(sections, state)),
  on(clearSections, state => sectionsAdapter.removeAll({ ...state, selectedUserId: null })),

  on(syncSections, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(syncSectionsSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(syncSectionsFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),
);

/**
 * The sections reducer
 */
export const sectionsReducer = (state: SectionsState, action: Action) => reducer(state, action);
