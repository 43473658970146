import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { ModalSyncComponent } from './modal-sync.component';
import { ButtonModule } from '../button/button.module';
import { DropDownModule } from '../drop-down/drop-down.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DropDownModule,
    ButtonModule
  ],
  exports: [
    ModalSyncComponent
  ],
  declarations: [
    ModalSyncComponent
  ]
})
export class ModalSyncModule { }
