/**
 * The Section model
 */
export class Section {
  /**
   * The unique identifier of the section
   */
  id: string;
  /**
   * The name used as title of the section
   */
  name: string;
  /**
   * A short description of the section
   */
  description?: string;
  projectId: string;
  boatId: any;
  imageId: any;
  position: any;
}
