import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Project } from 'src/app/models/project/Project';
import { DataService } from 'src/app/services/data/data.service';
import { loadProjects, syncProjects, syncProjectsFail, syncProjectsSuccess, upsertProjects } from './projects.actions';

@Injectable()
export class ProjectsEffects {

  syncProjects$ = createEffect(() => this.actions$.pipe(
    ofType(syncProjects),
    switchMap(() => this.dataService.getProjects().pipe(
      map((projects) => syncProjectsSuccess({ projects })),
      catchError(error => of(syncProjectsFail({ error })))
    ))
  ));

  syncProjectsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(syncProjectsSuccess),
    map((payload: { projects: Project[] }) => upsertProjects(payload))
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
