import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Image } from 'src/app/models/image/Image';
import { DataService } from 'src/app/services/data/data.service';
import { syncImage, syncImageFail, syncImageSuccess, upsertImage } from './images.actions';

@Injectable()
export class ImagesEffects {

  syncImage$ = createEffect(() => this.actions$.pipe(
    ofType(syncImage),
    switchMap((payload: { imageId: string; imageSize: string }) => this.dataService.getImage(payload.imageId, payload.imageSize).pipe(
      map((image) => syncImageSuccess({ image })),
      catchError(error => of(syncImageFail({ error })))
    ))
  ));

  syncImageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(syncImageSuccess),
    map((payload: { image: Image }) => upsertImage(payload))
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
