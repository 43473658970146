import { createAction, props } from '@ngrx/store';
import { Project } from 'src/app/models/project/Project';

/**
 * The load project action
 */
export const loadProject = createAction('[Load project]', props<{ projectId: string }>());

/**
 * The load project success action
 */
export const loadProjectSuccess = createAction('[Load project] success', props<{ project: Project }>());

/**
 * The load project fail action
 */
export const loadProjectFail = createAction('[Load project] fail', props<{ error: any }>());
