import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
  public titleLabel: string;
  public message: any = '';
  public dismissLabel: string;
  public confirmLabel: string;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.titleLabel = this.navParams.get('titleLabel');
    this.message = this.navParams.get('message');
    this.dismissLabel = this.navParams.get('dismissLabel');
    this.confirmLabel = this.navParams.get('confirmLabel');
  }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss(
      {
        confirmed: false
      }
    );
  }

  confirm() {
    this.modalController.dismiss(
      {
        confirmed: true
      }
    );
  }
}
