import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { DraftsEffects } from './drafts/drafts.effects';
import { draftsReducer } from './drafts/drafts.reducers';
import { loadingReducer } from './loading/loading.reducers';
import { LoginEffects } from './login/login.effects';
import { loginReducer } from './login/login.reducers';
import { networkReducer } from './network/network.reducers';
import { ProjectEffects } from './project/project.effects';
import { projectReducer } from './project/project.reducers';
import { projectsReducer } from './projects/projects.reducers';
import { ProjectsEffects } from './projects/projects.effects';
import { SectionsEffects } from './sections/sections.effects';
import { sectionsReducer } from './sections/sections.reducers';
import { imagesReducer } from './images/images.reducers';
import { ImagesEffects } from './images/images.effects';
import { tasksReducer } from './tasks/tasks.reducers';
import { TasksEffects } from './tasks/tasks.effects';
import { taskReducer } from './task/task.reducers';
import { syncingReducer } from './syncing/syncing.reducers';
import { InterventTypesEffects } from './interventTypes/interventTypes.effects';
import { interventTypesReducer } from './interventTypes/interventTypes.reducers';

export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> => localStorageSync({
  keys: ['login', 'images', 'interventTypes', 'projects', 'sections', 'drafts', 'tasks'],
  rehydrate: true
})(reducer);
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

/**
 * An array of store reducers
 */
export const appStoreModule = [
  StoreModule.forRoot({
    network: networkReducer,
    login: loginReducer,
    images: imagesReducer,
    interventTypes: interventTypesReducer,
    projects: projectsReducer,
    sections: sectionsReducer,
    drafts: draftsReducer,
    tasks: tasksReducer,
    // project: projectReducer
  }, {
    metaReducers
  }),
  StoreModule.forFeature('loading', loadingReducer),
  StoreModule.forFeature('syncing', syncingReducer),
  StoreModule.forFeature('project', projectReducer),
  StoreModule.forFeature('task', taskReducer),
  EffectsModule.forRoot([]),
  EffectsModule.forFeature([
    LoginEffects,
    ImagesEffects,
    InterventTypesEffects,
    ProjectEffects,
    ProjectsEffects,
    SectionsEffects,
    TasksEffects,
    DraftsEffects,
  ])
];
