import { Action, createReducer, on } from '@ngrx/store';
import { appInitialState } from '../AppInitialState';
// eslint-disable-next-line max-len
import { login, loginFail, loginSuccess, logout, logoutFail, logoutSuccess, recoverPassword, recoverPasswordFail, recoverPasswordSuccess, refreshToken, refreshTokenFail, refreshTokenSuccess, updateUserSuccess } from './login.actions';
import { LoginState } from './LoginState';

/**
 * The initial state for the login
 */
const initialState: LoginState = appInitialState.login;

/**
 * The reducer for the login state
 */
const reducer = createReducer(
  initialState,
  on(recoverPassword, (currentState) => ({
    ...currentState,
    error: null,
    isRecoveredPassword: false,
    isRecoveringPassword: true
  })),
  on(recoverPasswordSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isRecoveredPassword: true,
    isRecoveringPassword: false
  })),
  on(recoverPasswordFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isRecoveredPassword: false,
    isRecoveringPassword: false
  })),
  on(login, (currentState, action) => ({
    ...currentState,
    error: null,
    isLoggedIn: false,
    isLoggingIn: true,
    instance: action.instance
  })),
  on(loginSuccess, (currentState, action) => ({
    ...currentState,
    isLoggedIn: true,
    isLoggingIn: false,
    user: action.user
  })),
  on(loginFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggedIn: false,
    isLoggingIn: false,
    instance: null
  })),
  on(updateUserSuccess, (currentState, action) => ({
    ...currentState,
    user: {
      ...currentState.user,
      id: action.user.id,
      name: action.user.name,
      surname: action.user.surname,
      canWrite: action.user.can_write
    }

  })),
  on(refreshToken, (currentState) => ({
    ...currentState,
    error: null,
    isLoggedIn: false,
    isLoggingIn: true
  })),
  on(refreshTokenSuccess, (currentState, action) => ({
    ...currentState,
    isLoggedIn: true,
    isLoggingIn: false,
    user: {
      ...currentState.user,
      token: action.user.token
    }
  })),
  on(refreshTokenFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggedIn: false,
    isLoggingIn: false
  })),
  on(logout, (currentState) => ({
    ...currentState,
    error: null,
    isLoggedIn: true,
    isLoggingOut: true
  })),
  on(logoutSuccess, (currentState) => ({
    ...currentState,
    isLoggedIn: false,
    isLoggingOut: false,
    instance: null,
    user: null
  })),
  on(logoutFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggedIn: false,
    isLoggingOut: false
  }))
);

/**
 * The login reducer
 */
export const loginReducer = (state: LoginState, action: Action) => reducer(state, action);
