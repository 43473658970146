import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { ModalConfirmComponent } from './modal-confirm.component';
import { ButtonModule } from '../button/button.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ButtonModule
  ],
  exports: [
    ModalConfirmComponent
  ],
  declarations: [
    ModalConfirmComponent
  ]
})
export class ModalConfirmModule { }
