import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Image } from 'src/app/models/image/Image';
import { JsonApiService } from '../jsonapi/jsonapi.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private api: JsonApiService
  ) { }

  async getImage(imageId: string, size: string = null) {
    return new Observable<Image>(observer => {
      let routeImageSize = '';
      switch (size) {
        case 'thumb':
          routeImageSize = '/thumb';
          break;

        default:
          routeImageSize = '';
      }
      const route = `documents/${imageId}/show-base64${routeImageSize}`;
      this.api.customGetRequest(route).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const image = (response.data) ? response.data : null;
          observer.next(image);
        });
      });
    });
  }

  async addImage(imageData: any) {
    return new Observable<Image>(observer => {
      const addictionalPath = `create`;
      const documentData = {
        data: {
          type: 'documents',
          attributes: imageData
        }
      };
      this.api.postEntity('documents', documentData, addictionalPath).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const image = (response.data) ? response.data : null;
          observer.next(image);
        });
      });
    });
  }
}
