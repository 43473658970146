// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  padding: 16px;
  color: #FFFFFF;
  text-align: right;
}
.modal-header > ion-icon {
  font-size: 32px;
}

ion-content {
  --background: transparent;
}

swiper {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-zoom/modal-zoom.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,iBAAA;AACJ;AAGI;EACI,eAAA;AADR;;AAKA;EACI,yBAAA;AAFJ;;AAKA;EACI,YAAA;AAFJ","sourcesContent":[".modal-header {\n    padding: 16px;\n    color: #FFFFFF;\n    text-align: right;\n\n    // background-color: #000000;\n\n    > ion-icon {\n        font-size: 32px;\n    }\n}\n\nion-content {\n    --background: transparent;\n}\n\nswiper {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
