/**
 * The Intervent Type model
 */
export class InterventType {
  /**
   * The unique identifier of the intervent type
   */
  id: string;
  /**
   * The name used as title of the intervent type
   */
  name: string;
  /**
   * A short description of the intervent type
   */
  description?: string;
}
