import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data/data.service';
import { loadProject, loadProjectFail, loadProjectSuccess } from './project.actions';

@Injectable()
export class ProjectEffects {

  loadProject$ = createEffect(() => this.actions$.pipe(
    ofType(loadProject),
    switchMap((payload: { projectId: string }) => this.dataService.loadProject(payload.projectId).pipe(
      map(project => loadProjectSuccess({ project })),
      catchError(error => of(loadProjectFail({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
