import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Boat } from 'src/app/models/boat/Boat';
import { Project } from 'src/app/models/project/Project';
import { Section } from 'src/app/models/section/Section';
import { ErrorHandlerService } from '../jsonapi/error-handler.service';
import { JsonApiService } from '../jsonapi/jsonapi.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private api: JsonApiService
  ) { }

  async getBoats() {
    return new Observable<Project[]>(observer => {
      this.api.getCollection('boats').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const boats = (response.data) ? response.data : [];
          // console.log('boats', boats);
          const projects = [];
          observer.next(projects);
        }, (error) => {
          observer.error(error);
        });
      });
    });
  }

  async getProjects(params: { filters: { name: string; value: any }[]; includes: string[] } = null) {
    return new Observable<Project[]>(observer => {
      let filters = [];
      if (params && params.filters) {
        filters = params.filters;
      }

      // ToDo+
      const route = `projects/active`;
      this.api.customGetRequest(route).then((call$) => {

        // this.api.getCollection('projects', filters).then((call$) => {

        call$.pipe(take(1)).subscribe({
          next: (response: any) => {
            // console.log('response', response);
            const projects = (response.data) ? response.data : [];
            // console.log('projects', projects);
            observer.next(projects);
          },
          error: (error) => {
            observer.error(error);
          }
        });

        // call$.pipe(take(1)).subscribe((response: any) => {
        //   const projects = (response.data) ? response.data : [];
        //   observer.next(projects);
        // }, (error) => {
        //   observer.error(error);
        // });
      });
    });
  }

  async getProjectBoat(projectId: string) {
    return new Observable<Boat>(observer => {
      this.api.getRelatedCollection('projects', projectId, 'boat').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const boat = (response.data) ? response.data : [];
          observer.next(boat);
        });
      });
    });
  }

  async getProjectSections(boatId: string) {
    return new Observable<Section[]>(observer => {
      this.api.getRelatedCollection('boats', boatId, 'sections').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const projects = (response.data) ? response.data : [];
          observer.next(projects);
        });
      });
    });
  }
}
