import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Boat } from 'src/app/models/boat/Boat';
import { InterventType } from 'src/app/models/interventType/InterventType';
import { Section } from 'src/app/models/section/Section';
import { Task } from 'src/app/models/task/Task';
import { ErrorHandlerService } from '../jsonapi/error-handler.service';
import { JsonApiService } from '../jsonapi/jsonapi.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private api: JsonApiService
  ) { }

  async getInterventTypes() {
    return new Observable<InterventType[]>(observer => {
      this.api.getCollection('task-intervent-types').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const interventTypes = (response.data) ? response.data : [];
          observer.next(interventTypes);
        }, (error) => {
          observer.error(error);
        });
      });
    });
  }

  async getTasks() {
    return new Observable<Task[]>(observer => {
      this.api.getCollection('tasks').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const tasks = (response.data) ? response.data : [];
          observer.next(tasks);
        }, (error) => {
          observer.error(error);
        });
      });
    });
  }

  async getTask(taskId: string) {
    return new Observable<Task>(observer => {
      this.api.getEntity('tasks', taskId).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const task = (response.data) ? response.data : null;
          observer.next(task);
        }, (error) => {
          observer.error(error);
        });
      });
    });
  }

  async getProjectTasks(projectId: string) {
    return new Observable<Task[]>(observer => {
      this.api.getRelatedCollection('projects', projectId, 'tasks').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const tasks = (response.data) ? response.data : [];
          observer.next(tasks);
        });
      });
    });
  }

  async getTaskHistory(taskId: string) {
    return new Observable<any[]>(observer => {
      this.api.getRelatedCollection('tasks', taskId, 'history').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const history = (response.data) ? response.data : [];
          observer.next(history);
        });
      });
    });
  }

  // async getSectionTasks(sectionId: string) {
  //   return new Observable<Section[]>(observer => {
  //     this.api.getRelatedCollection('boats', sectionId, 'sections').then((call$) => {
  //       call$.pipe(take(1)).subscribe((response: any) => {
  //         const projects = (response.data) ? response.data : [];
  //         observer.next(projects);
  //       });
  //     });
  //   });
  // }

  async addTask(dataAttributes: any) {
    return new Observable<Task>(observer => {
      const taskData: any = {
        data: {
          type: 'tasks',
          attributes: dataAttributes
        }
      };
      this.api.postEntity('tasks', taskData).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const task = (response.data) ? response.data : null;
          observer.next(task);
        });
      });
    });
  }

  async updateTask(taskId: string, dataAttributes: any) {
    return new Observable<Task>(observer => {
      const taskData: any = {
        data: {
          type: 'tasks',
          id: taskId,
          attributes: dataAttributes
        }
      };
      this.api.updateEntity('tasks', taskId, taskData).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const task = (response.data) ? response.data : null;
          observer.next(task);
        });
      });
    });
  }

  async deleteTask(taskId: string) {
    return new Observable<boolean>(observer => {
      this.api.deleteEntity('tasks', taskId).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          observer.next(true);
        });
      });
    });
  }

  async deleteHistoryImage(historyId: string, imageId: string) {
    return new Observable<boolean>(observer => {
      const data = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        document_id: imageId
      };
      const addictionalPath = `${historyId}/image-delete`;
      this.api.postEntity('histories', data, addictionalPath).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          observer.next(true);
        });
      });
    });
  }

  addCommentToTaskHistory(taskHistoryId: string, commentText: string) {
    return new Observable<any>(observer => {
      const commentData: any = {
        data: {
          type: 'comments',
          attributes: {
            body: commentText
          }
        }
      };
      const path = 'histories/' + taskHistoryId + '/add-comment';
      this.api.postEntityCustomPath(commentData, path).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          observer.next(true);
        });
      });
    });
  }

  updateComment(commentId: string, commentText: string) {
    return new Observable<any>(observer => {
      const commentData: any = {
        data: {
          type: 'comments',
          id: commentId,
          attributes: {
            body: commentText
          }
        }
      };
      this.api.updateEntity('comments', commentId, commentData).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const comment = (response.data) ? response.data : null;
          observer.next(comment);
        });
      });
    });
  }
}
