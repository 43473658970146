import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ModalZoomComponent } from './modal-zoom.component';
import { ButtonModule } from '../button/button.module';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    ButtonModule
  ],
  exports: [
    ModalZoomComponent
  ],
  declarations: [
    ModalZoomComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalZoomModule { }
