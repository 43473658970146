import { Action, createReducer, on } from '@ngrx/store';
import { appInitialState } from '../AppInitialState';
import { hide, show } from './loading.actions';
import { LoadingState } from './LoadingState';

/**
 * The initial state for the loading
 */
const initialState: LoadingState = appInitialState.loading;

/**
 * The reducer for the loading state
 */
const reducer = createReducer(
  initialState,
  on(show, () => ({
    show: true
  })),
  on(hide, () => ({
    show: false
  }))
);

/**
 * The loading reducer
 */
export const loadingReducer = (state: LoadingState, action: Action) => reducer(state, action);
