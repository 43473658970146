import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { InterventType } from 'src/app/models/interventType/InterventType';

export const loadInterventTypes = createAction('[InterventType/API] Load InterventTypes', props<{ interventTypes: InterventType[] }>());
export const setInterventTypes = createAction('[InterventType/API] Set InterventTypes', props<{ interventTypes: InterventType[] }>());
export const addInterventType = createAction('[InterventType/API] Add InterventType', props<{ interventType: InterventType }>());
export const setInterventType = createAction('[InterventType/API] Set InterventType', props<{ interventType: InterventType }>());
export const upsertInterventType = createAction('[InterventType/API] Upsert InterventType', props<{ interventType: InterventType }>());
export const addInterventTypes = createAction('[InterventType/API] Add InterventTypes', props<{ interventTypes: InterventType[] }>());
export const upsertInterventTypes = createAction('[InterventType/API] Upsert InterventTypes', props<{ interventTypes: InterventType[] }>());
export const updateInterventType = createAction('[InterventType/API] Update InterventType', props<{ update: Update<InterventType> }>());
// eslint-disable-next-line max-len
export const updateInterventTypes = createAction('[InterventType/API] Update InterventTypes', props<{ updates: Update<InterventType>[] }>());
export const mapInterventType = createAction('[InterventType/API] Map InterventType', props<{ entityMap: EntityMapOne<InterventType> }>());
export const mapInterventTypes = createAction('[InterventType/API] Map InterventTypes', props<{ entityMap: EntityMap<InterventType> }>());
export const deleteInterventType = createAction('[InterventType/API] Delete InterventType', props<{ id: string }>());
export const deleteInterventTypes = createAction('[InterventType/API] Delete InterventTypes', props<{ ids: string[] }>());
// eslint-disable-next-line max-len
export const deleteInterventTypesByPredicate = createAction('[InterventType/API] Delete InterventTypes By Predicate', props<{ predicate: Predicate<InterventType> }>());
export const clearInterventTypes = createAction('[InterventType/API] Clear InterventTypes');

/**
 * The sync action
 */
export const syncInterventTypes = createAction('[InterventTypes/API] Sync');

/**
 * The syncInterventTypes success action
 */
export const syncInterventTypesSuccess = createAction('[InterventTypes/API] Sync success', props<{ interventTypes: InterventType[] }>());

/**
 * The syncInterventTypes fail action
 */
export const syncInterventTypesFail = createAction('[InterventTypes/API] Sync fail', props<{ error: any }>());
