import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/data/data.service';
// eslint-disable-next-line max-len
import { login, loginFail, loginSuccess, logout, logoutFail, logoutSuccess, recoverPassword, recoverPasswordFail, recoverPasswordSuccess, refreshToken, refreshTokenFail, refreshTokenSuccess, updateUserSuccess, updateUserFail } from './login.actions';

@Injectable()
export class LoginEffects {

  recoverPassword$ = createEffect(() => this.actions$.pipe(
    ofType(recoverPassword),
    switchMap((payload: { email: string }) => this.authService.recoverEmailPassword(payload.email).pipe(
      map(() => recoverPasswordSuccess()),
      catchError(error => of(recoverPasswordFail({ error })))
    ))
  ));

  recoverPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(recoverPasswordSuccess),
    map(() => recoverPasswordFail({ error: null })),
    catchError(error => of(recoverPasswordFail({ error })))
  ));

  login$ = createEffect(() => this.actions$.pipe(
    ofType(login),
    switchMap((payload: {
      email: string; password: string; instance: string;
    }) => this.authService.login(payload.email, payload.password, payload.instance).pipe(
      map((user) => loginSuccess({ user })),
      catchError(error => of(loginFail({ error })))
    ))
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loginSuccess),
    switchMap(() => this.dataService.getUser().pipe(
      map((user) => updateUserSuccess({ user })),
      catchError(error => of(updateUserFail({ error })))
    ))
  ));

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(refreshToken),
    switchMap((payload: { refreshToken: string }) => this.authService.refreshToken(payload.refreshToken).pipe(
      map((user) => refreshTokenSuccess({ user })),
      catchError(error => of(refreshTokenFail({ error })))
    ))
  ));

  refreshTokenSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loginSuccess),
    switchMap(() => this.dataService.getUser().pipe(
      map((user) => updateUserSuccess({ user })),
      catchError(error => of(updateUserFail({ error })))
    ))
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    switchMap(() => this.authService.logout().pipe(
      map(user => logoutSuccess()),
      catchError(error => of(logoutFail({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private dataService: DataService
  ) { }
}
