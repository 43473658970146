/**
 * The Image model
 */
export class Image {
  /**
   * The unique identifier of the image
   */
  id: string;
  /**
   * The base64 of the image
   */
  base64: string;
  /**
   * The url of the image
   */
  url?: string;
}
