import { Action, createReducer, on } from '@ngrx/store';
import { appInitialState } from '../AppInitialState';
import { loadProject, loadProjectFail, loadProjectSuccess } from './project.actions';
import { ProjectState } from './ProjectState';

/**
 * The initial state for the project
 */
const initialState: ProjectState = appInitialState.project;

/**
 * The reducer for the project state
 */
const reducer = createReducer(
  initialState,
  on(loadProject, (currentState) => ({
    ...currentState,
    error: null,
    isReading: true,
    isReaded: false,
    item: null
  })),
  on(loadProjectSuccess, (currentState, action) => ({
    ...currentState,
    error: null,
    isReading: false,
    isReaded: true,
    item: action.project
  })),
  on(loadProjectFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isReading: false,
    isReaded: false,
    item: null
  }))
);

/**
 * The project reducer
 */
export const projectReducer = (state: ProjectState, action: Action) => reducer(state, action);
