/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-len
import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, OnInit } from '@angular/core';
import { } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise, auditTime, last, throttle, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: [
    './canvas.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CanvasComponent implements OnInit, OnChanges {

  @Input() set width(val: number) {
    // console.log('value: ', val);
    this._width = (val !== undefined && val !== null) ? val : 0;
  }

  @Input() set height(val: number) {
    this._height = (val !== undefined && val !== null) ? val : 0;
  }

  @Input() set dpr(val: number) {
    // console.log('value: ', val);
    this._dpr = (val !== undefined && val !== null) ? val : 1;
  }

  @Input() set image(val: string) {
    this._image = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set toolType(val: string) {
    this._toolType = (val !== undefined && val !== null) ? val : 'pencil';
  }

  @Input() set lineWidth(val: number) {
    this._lineWidth = (val !== undefined && val !== null) ? val : 3;
  }

  @Input() set lineCap(val: CanvasLineCap) {
    this._lineCap = (val !== undefined && val !== null) ? val : 'round';
  }

  @Input() set lineColor(val: string) {
    this._lineColor = (val !== undefined && val !== null) ? val : '#000';
  }

  @Output() hasUndo = new EventEmitter();

  @Output() hasRedo = new EventEmitter();

  // a reference to the canvas element from our template
  @ViewChild('canvas', { read: ElementRef }) private canvas: ElementRef;
  @ViewChild('canvasEdit', { read: ElementRef }) private canvasEdit: ElementRef;

  private _width: number;
  private _height: number;
  private _dpr: number;
  private _image: string;
  private _toolType: string;
  private _lineWidth: number;
  private _lineCap: CanvasLineCap;
  private _lineColor: string;

  private ctx: CanvasRenderingContext2D;
  private ctxEdit: CanvasRenderingContext2D;

  private redoList: Array<any>;
  private undoList: Array<any>;

  private alreadyInit = false;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {
  }

  ngOnChanges() {
    // console.log('ngOnChanges', this.undoList);
    this.undoList = [];
    this.redoList = [];
    if (this.canvas) {
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      this.ctx = canvasEl.getContext('2d');
      this.ctx.lineWidth = this._lineWidth;
      this.ctx.lineCap = this._lineCap;
      this.ctx.strokeStyle = this._lineColor;
      const canvasTempEl: HTMLCanvasElement = this.canvasEdit.nativeElement;
      this.ctxEdit = canvasTempEl.getContext('2d');
      this.ctxEdit.lineWidth = this._lineWidth;
      this.ctxEdit.lineCap = this._lineCap;
      this.ctxEdit.strokeStyle = this._lineColor;
    }
  }

  ngOnInit() {
    this.undoList = [];
    this.redoList = [];
  }

  public initCanvas() {
    // console.log('initCanvas');
    // get the context
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    const canvasTempEl: HTMLCanvasElement = this.canvasEdit.nativeElement;

    // set the width and height
    canvasEl.width = this._width * this._dpr;
    canvasEl.height = this._height * this._dpr;
    canvasTempEl.width = this._width * this._dpr;
    canvasTempEl.height = this._height * this._dpr;

    this.ctx = canvasEl.getContext('2d');
    this.ctx.scale(this._dpr, this._dpr);
    this.ctxEdit = canvasTempEl.getContext('2d');
    this.ctxEdit.scale(this._dpr, this._dpr);

    const background = new Image();
    background.src = this._image;
    background.setAttribute('crossOrigin', 'anonymous');

    // Make sure the image is loaded first otherwise nothing will draw.
    background.onload = () => {
      const canvasRatio = this._width / this._height;
      const backgroundRatio = background.width / background.height;

      if (backgroundRatio > canvasRatio) {
        //
        const width = this._width;
        const height = Math.ceil(this._width / background.width * background.height);
        const x = 0;
        const y = Math.ceil((this._height - height) / 2);
        this.ctx.drawImage(background, x, y, width, height);
      }
      else {
        const width = Math.ceil(this._height / background.height * background.width);
        const height = this._height;
        const x = Math.ceil((this._width - width) / 2);
        const y = 0;
        this.ctx.drawImage(background, x, y, width, height);
      }
    };

    // set some default properties about the line
    this.ctx.lineWidth = this._lineWidth;
    this.ctx.lineCap = this._lineCap;
    this.ctx.strokeStyle = this._lineColor;
    // set some default properties about the line
    this.ctxEdit.lineWidth = this._lineWidth;
    this.ctxEdit.lineCap = this._lineCap;
    this.ctxEdit.strokeStyle = this._lineColor;

    this.undoList = [];
    this.redoList = [];

    if (this.isTouchDevice()) {
      // we'll implement this method to start capturing touch events
      this.captureTouchEvents(canvasTempEl);
    }
    else {
      // we'll implement this method to start capturing mouse events
      this.captureMouseEvents(canvasTempEl);
    }
  }

  public undo() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.ctx = canvasEl.getContext('2d');

    this.restoreState(canvasEl, this.ctx, this.undoList, this.redoList);
  }

  public redo() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.ctx = canvasEl.getContext('2d');

    this.restoreState(canvasEl, this.ctx, this.redoList, this.undoList);
  }

  public restart() {
    do {
      this.undo();
    } while (this.undoList.length > 1);
  }


  public getCurrent() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;

    return this.cropImageFromCanvas(this.ctx, canvasEl);
    // return canvasEl.toDataURL();
  }

  private isTouchDevice() {
    return (
      ('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0));
  }

  private captureMouseEvents(canvasEl: HTMLCanvasElement) {
    let rect = canvasEl.getBoundingClientRect();
    let startPos = {
      x: 0,
      y: 0
    };
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e: MouseEvent) => {
          rect = canvasEl.getBoundingClientRect();
          // after a mouse down, we'll record first position
          startPos = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
          };
          // and all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        switch (this._toolType) {
          case 'circle':
            this.drawCicleOnCanvas(startPos, currentPos);
            break;

          case 'rect':
            this.drawRectOnCanvas(startPos, currentPos);
            break;

          case 'pencil':
          default:
            this.drawPencilOnCanvas(prevPos, currentPos);
            break;
        }
      });

    fromEvent(canvasEl, 'mouseup')
      .pipe(
        // throttleTime(500)
      )
      .subscribe(() => {
        this.ctx.drawImage(canvasEl, 0, 0);
        this.ctxEdit.clearRect(0, 0, this._width, this._height);
        this.saveState(canvasEl, null, null);
      });
  }

  private captureTouchEvents(canvasEl: HTMLCanvasElement) {
    let rect = canvasEl.getBoundingClientRect();
    let startPos = {
      x: 0,
      y: 0
    };
    // this will capture all touch events from the canvas element
    fromEvent(canvasEl, 'touchstart', { passive: true, capture: true })
      .pipe(
        switchMap((e: TouchEvent) => {
          rect = canvasEl.getBoundingClientRect();
          // after a touch start, we'll record first position
          startPos = {
            x: e.touches[0].clientX - rect.left,
            y: e.touches[0].clientY - rect.top
          };
          // and all touch moves
          return fromEvent(canvasEl, 'touchmove', { passive: true, capture: true })
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'touchend', { passive: true, capture: true })),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'touchleave', { passive: true, capture: true })),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
        // previous and current position with the offset
        const prevPos = {
          x: res[0].touches[0].clientX - rect.left,
          y: res[0].touches[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].touches[0].clientX - rect.left,
          y: res[1].touches[0].clientY - rect.top
        };


        switch (this._toolType) {
          case 'circle':
            this.drawCicleOnCanvas(startPos, currentPos);
            break;

          case 'rect':
            this.drawRectOnCanvas(startPos, currentPos);
            break;

          case 'pencil':
          default:
            this.drawPencilOnCanvas(prevPos, currentPos);
            break;
        }
      });

    fromEvent(canvasEl, 'touchend')
      .pipe(
        // throttleTime(500)
      )
      .subscribe(() => {
        this.ctx.drawImage(canvasEl, 0, 0);
        this.ctxEdit.clearRect(0, 0, this._width, this._height);
        this.saveState(canvasEl, null, false);
      });
  }

  private drawPencilOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    // incase the context is not set
    if (!this.ctx) { return; }

    // start our drawing path
    this.ctx.beginPath();

    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.ctx.moveTo(prevPos.x, prevPos.y); // from

      // draws a line from the start pos until the current position
      this.ctx.lineTo(currentPos.x, currentPos.y);

      // strokes the current path with the styles we set earlier
      this.ctx.stroke();
    }
  }

  private drawRectOnCanvas(
    startPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    // incase the context is not set
    if (!this.ctxEdit) { return; }

    // start our drawing path
    this.ctxEdit.beginPath();

    // we're drawing lines so we need a previous position
    if (startPos) {
      const x = Math.min(currentPos.x, startPos.x);
      const y = Math.min(currentPos.y, startPos.y);
      const w = Math.abs(currentPos.x - startPos.x);
      const h = Math.abs(currentPos.y - startPos.y);

      this.ctxEdit.clearRect(0, 0, this._width, this._height);

      if (!w || !h) {
        return;
      }

      this.ctxEdit.strokeRect(x, y, w, h);
    }
  }

  private drawCicleOnCanvas(
    centerPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    // incase the context is not set
    if (!this.ctxEdit) { return; }

    // start our drawing path
    this.ctxEdit.beginPath();

    // we're drawing lines so we need a previous position
    if (centerPos) {
      const w = Math.abs(currentPos.x - centerPos.x);
      const h = Math.abs(currentPos.y - centerPos.y);
      const radius = Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2));
      this.ctxEdit.clearRect(0, 0, this._width, this._height);

      if (!radius) {
        return;
      }

      // // draws an arc with the center pos and radius to the current position
      this.ctxEdit.arc(centerPos.x, centerPos.y, radius, 0, 2 * Math.PI, false);

      // // strokes the current path with the styles we set earlier
      this.ctxEdit.stroke();
    }
  }

  private saveState(canvasEl: HTMLCanvasElement, list: Array<any>, keepRedo: boolean) {
    keepRedo = keepRedo || false;
    if (!keepRedo) {
      this.redoList = [];
    }

    (list || this.undoList).push(canvasEl.toDataURL());
    // console.log('save this.undoList.length', this.undoList.length);
    this.hasUndo.emit(this.undoList.length);
    this.hasRedo.emit(this.redoList.length);
  }

  private restoreState(canvasEl: HTMLCanvasElement, ctx: CanvasRenderingContext2D, pop: Array<any>, push: Array<any>) {
    if (pop.length) {
      this.saveState(canvasEl, push, true);
      const restoreState = pop.pop();
      const background = new Image();
      background.src = restoreState;
      background.setAttribute('crossOrigin', 'anonymous');

      // Make sure the image is loaded first otherwise nothing will draw.
      background.onload = () => {
        ctx.clearRect(0, 0, this._width, this._height);
        ctx.drawImage(background, 0, 0, this._width, this._height);
      };
    }
    this.hasUndo.emit(this.undoList.length);
    this.hasRedo.emit(this.redoList.length);
  }

  private cropImageFromCanvas(ctx, canvas) {

    let w = canvas.width;
    let h = canvas.height;
    const pix = { x: [], y: [] };
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let x: number;
    let y: number;
    let index: number;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {

          pix.x.push(x);
          pix.y.push(y);

        }
      }
    }
    pix.x.sort((a, b) => a - b);
    pix.y.sort((a, b) => a - b);
    const n = pix.x.length - 1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    const cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

    canvas.width = w;
    canvas.height = h;
    ctx.putImageData(cut, 0, 0);

    return canvas.toDataURL();
  }
}
