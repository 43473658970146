/**
 * The Task model
 */
export class Task {
  /**
   * The unique identifier of the task
   */
  id: string;
  /**
   * The name used as title of the task
   */
  name: string;
  /**
   * A short description of the task
   */
  description?: string;
  /**
   * The project ID
   */
  projectId: string;
  /**
   * The section ID
   */
  sectionId: string;
  /**
   * The section name
   */
  sectionName: string;
  /**
   * The task Status
   */
  status: string;
  /**
   * The x coordinate on the section map
   */
  x: number;
  /**
   * The y coordinate on the section map
   */
  y: number;
  internalProgressiveNumber: number;
  author: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  ipn: string;
  interventTypeId: string;
  interventTypeName: string;
  isOpen: boolean;
  isPrivate: boolean;
  lastEditor: string;
  lastEditorId: string;
  lastHistory: any;
  history: any[];
  subsectionId: string;
  type: string;
  zone: any;
  authorInitials: string;
  lastEditorInitials: string;
  createdAtText: string;
  updatedAtText: string;

  resolutionDate: string; // resolution_date
  dreamDocument: any; // dream_document
  userInCharge: any; // user_in_charge

  openerApplicationLogId: string;
  openerApplicationLogName: string;
  closerApplicationLogId: string;
  closerApplicationLogName: string;

  taskNumber: number;
  workedHours: number;
  estimatedHours: number;

}
