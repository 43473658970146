import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/models/user/User';
import { Token } from 'src/app/models/token/Token';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/states/AppState';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';

/**
 * A service to manage the authentication in APP
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isMultiInstance = environment.isMultiInstance;
  storagePrefix = (!this.isMultiInstance) ? environment.storage.prefix : null;
  authBaseUrl = (!this.isMultiInstance) ? environment.auth.baseUrl : null;
  authClientId = (!this.isMultiInstance) ? environment.auth.clientId : null;
  authClientSecret = (!this.isMultiInstance) ? environment.auth.clientSecret : null;
  authScope = (!this.isMultiInstance) ? environment.auth.scope : null;

  /**
   * The contructor
   */
  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient
  ) { }

  getInstance(forcedInstance: string = null): Observable<string> {
    if (forcedInstance && forcedInstance.length) {
      return of(forcedInstance);
    }

    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.instance) {
          return of(loginState.instance);
        }
        return of('');
      })
    );
  }

  public buildAuthBaseUrl(forcedInstance: string = null) {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          let authBaseUrl = environment.auth.baseUrl;
          if (instance && instance.length) {
            authBaseUrl = '';
            const instanceParams = environment.multiInstance[instance];
            if (instanceParams) {
              authBaseUrl = instanceParams.auth.baseUrl;
            }
          }
          resolve(authBaseUrl);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public buildAuthClientId(forcedInstance: string = null) {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          let authClientId = environment.auth.clientId;
          if (instance && instance.length) {
            authClientId = '';
            const instanceParams = environment.multiInstance[instance];
            if (instanceParams) {
              authClientId = instanceParams.auth.clientId;
            }
          }
          resolve(authClientId);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public buildAuthClientSecret(forcedInstance: string = null) {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          let authClientSecret = environment.auth.clientSecret;
          if (instance && instance.length) {
            authClientSecret = '';
            const instanceParams = environment.multiInstance[instance];
            if (instanceParams) {
              authClientSecret = instanceParams.auth.clientSecret;
            }
          }
          resolve(authClientSecret);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public buildAuthScope(forcedInstance: string = null) {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          let authScope = environment.auth.scope;
          if (instance && instance.length) {
            authScope = '';
            const instanceParams = environment.multiInstance[instance];
            if (instanceParams) {
              authScope = instanceParams.auth.scope;
            }
          }
          resolve(authScope);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  /**
   * Call the recover password functionality
   *
   * @param email
   * The user email
   *
   * @returns
   * An observable
   */
  recoverEmailPassword(email: string): Observable<void> {
    return new Observable<void>(observer => {
      // TODO
      setTimeout(() => {
        if ('error@email.com' === email) {
          observer.error({
            message: 'Email not found'
          });
        }
        observer.next();
        observer.complete();
      }, 3000);

      // this.auth.sendPasswordResetEmail(email).then(() => {
      //   observer.next();
      //   observer.complete();
      // }).catch(error => {
      //   observer.next(error);
      //   observer.complete();
      // });
    });
  }

  /**
   * Call the login functionality
   *
   * @param email
   * The user email
   *
   * @param password
   * The user password
   *
   * @returns
   * An observable
   */
  login(email: string, password: string, instance: string = null): Observable<User> {
    return new Observable<User>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl(instance);
        const authClientId = await this.buildAuthClientId(instance);
        const authClientSecret = await this.buildAuthClientSecret(instance);
        const authScope = await this.buildAuthScope(instance);

        const body = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          grant_type: 'password',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          client_id: authClientId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          client_secret: authClientSecret,
          username: email,
          password,
          scope: authScope
        };
        const options = {};
        this.httpClient.post(`${authBaseUrl}/token`, body, options).subscribe(
          (res: any) => {
            // console.log('res', res);
            const user = new User();
            user.email = email;
            user.id = 'userId';
            const token = new Token();
            token.accessToken = res.access_token;
            token.expiresIn = res.expires_in;
            token.refreshToken = res.refresh_token;
            token.tokenType = res.token_type;
            user.token = token;
            observer.next(user);
          },
          (error: any) => {
            // console.log('error', error);
            observer.error({
              message: 'User not found'
            });
            observer.next();
          }
        );
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  refreshToken(refreshToken: string): Observable<User> {
    return new Observable<User>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl();
        const authClientId = await this.buildAuthClientId();
        const authClientSecret = await this.buildAuthClientSecret();
        const authScope = await this.buildAuthScope();

        const body = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          grant_type: 'refresh_token',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          client_id: authClientId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          client_secret: authClientSecret,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          refresh_token: refreshToken,
          scope: authScope
        };
        const options = {};
        this.httpClient.post(`${authBaseUrl}/token`, body, options).subscribe(
          (res: any) => {
            const user = new User();
            user.email = 'userEmail';
            user.id = 'userId';
            const token = new Token();
            token.accessToken = res.access_token;
            token.expiresIn = res.expires_in;
            token.refreshToken = res.refresh_token;
            token.tokenType = res.token_type;
            user.token = token;
            observer.next(user);
          },
          (error: any) => {
            // console.log('error', error);
            observer.error({
              message: 'User not found'
            });
            observer.next();
          }
        );
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  logout(): Observable<void> {
    return new Observable<void>(observer => {
      // TODO
      setTimeout(() => {
        observer.next();
        observer.complete();
      }, 1000);
    });
  }

}
