// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: left;
}

p {
  text-align: left;
}

.inner-content {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-sync/modal-sync.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":["h1 {\n    margin-top: 20px;\n    margin-bottom: 60px;\n    text-align: left;\n}\n\np {\n    text-align: left;\n}\n\n.inner-content {\n    padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
