/**
 * The Boat model
 */
export class Boat {
  /**
   * The unique identifier of the boat
   */
  id: string;
  /**
   * The name used as title of the boat
   */
  name: string;
  /**
   * A short description of the boat
   */
  description?: string;
  imageId: any;
  sections: string;
}
