import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Task } from 'src/app/models/task/Task';
// import * as _ from 'lodash';

@Component({
  selector: 'app-modal-intervent-type',
  templateUrl: './modal-intervent-type.component.html',
  styleUrls: ['./modal-intervent-type.component.scss'],
})
export class ModalInterventTypeComponent implements OnInit {
  public task: Task = null;
  public interventType: any = null;
  public taskInterventTypes: any[] = [];

  public taskCircleIcons: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.task = this.navParams.get('task');
    this.taskInterventTypes = this.navParams.get('interventTypes');
    // console.log('this.taskInterventTypes', this.taskInterventTypes);
    this.interventType = (this.taskInterventTypes) ? this.taskInterventTypes.find(x => x.id === this.task.interventTypeId) : null;
  }

  ngOnInit() {
  }

  async dismiss(data: any) {
    await this.modalController.dismiss({
      dismissed: true,
      taskId: this.task.id,
      interventType: this.interventType,
      data
    });
  }

  changeTaskInterventType() {
    this.dismiss({
      confirmed: true,
      interventType: this.interventType,
      task: this.task,
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  onInterventTypeClick(interventType) {
    this.interventType = interventType;
  }

  orderTypes(typeA: Task, typeB: Task, args: any) {
    if (args && (args.length > 0)) {
      let valueA = null;
      let valueB = null;
      switch (args) {
        case 'name-asc':
          valueA = typeA.name.toLowerCase();
          valueB = typeB.name.toLowerCase();
          break;

        case 'name-desc':
          valueB = typeA.name.toLowerCase();
          valueA = typeB.name.toLowerCase();
          break;

        case 'id-asc':
          valueA = new Date(typeA.id);
          valueB = new Date(typeB.id);
          break;

        case 'id-desc':
          valueB = new Date(typeA.id);
          valueA = new Date(typeB.id);
          break;
      }
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      // values must be equal
      return 0;
    }
  }
}
