import { AppState } from './AppState';

/**
 * All APP initial states
 */
// export const appInitialState: AppState = {
export const appInitialState: any = {
  loading: {
    show: false
  },
  network: {
    connected: false,
    connectionType: 'unknown'
  },
  login: {
    error: null,
    isRecoveredPassword: false,
    isRecoveringPassword: false,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    instance: null,
    user: null
  },
  syncing: {
    imagesToSync: false,
    imagesSyncing: false,
    imagesSynced: false,
  },
  // projects: {
  //   ids: [],
  //   entities: {},
  //   error: null,
  //   isReading: false,
  //   isReaded: false,
  //   items: []
  // },
  project: {
    error: null,
    isReading: false,
    isReaded: false,
    isAdding: false,
    isAdded: false,
    isUpdating: false,
    isUpdated: false,
    isRemoving: false,
    isRemoved: false,
    item: null
  },
  task: {
    error: null,
    isAdding: false,
    isAdded: false,
    isUpdating: false,
    isUpdated: false,
    isRemoving: false,
    isRemoved: false,
    item: null
  },
  drafts: {
    error: null,
    isSending: false,
    isSended: false
  }
};
