/**
 * The Token model
 */
export class Token {
  /**
   * The access token
   */
  accessToken: string;
  /**
   * The expiration time
   */
  expiresIn: number;
  /**
   * The refresh token
   */
  refreshToken: string;
  /**
   * The token type
   */
  tokenType: string;
}
