/**
 * The TaskHistory model
 */
export class TaskHistory {
  /**
   * The unique identifier of the task history
   */
  id: string;
  comments: any;
  photos: any;
  documents: any;

}
