// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: left;
}

p {
  text-align: left;
}

.inner-content {
  padding: 16px;
}

.task-intervent-type-list {
  margin-bottom: 32px;
}
.task-intervent-type-list .task-intervent-type-list-label {
  display: block;
  margin-bottom: 8px;
  color: #000000;
  font-size: 8px;
  font-weight: bold;
  line-height: 10px;
  text-transform: uppercase;
}

.task-intervent-type-row {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 80px;
  margin: 0 0 16px;
  padding: 16px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.task-intervent-type-code {
  flex-basis: 48px;
  flex-grow: 0;
}

.task-intervent-type-label {
  flex-grow: 1;
  margin: 0 16px;
  color: #DDDDDD;
  font-size: 16px;
  font-weight: bold;
}
.task-intervent-type-label.intervent-type {
  color: #000000;
}

.task-intervent-type-radio {
  flex-basis: 32px;
  flex-grow: 0;
}
.task-intervent-type-radio > ion-icon {
  color: #DDDDDD;
  font-size: 32px;
}
.task-intervent-type-radio.checked > ion-icon {
  color: #0A6FDB;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-intervent-type/modal-intervent-type.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;AACI;EACI,cAAA;EACA,kBAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;AACR;;AAGA;EACI,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,YAAA;EACA,cAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAEI;EACI,cAAA;AAAR;;AAOA;EACI,gBAAA;EACA,YAAA;AAJJ;AAMI;EACI,cAAA;EACA,eAAA;AAJR;AAQQ;EACI,cAAA;AANZ","sourcesContent":["h1 {\n    margin-top: 20px;\n    margin-bottom: 60px;\n    text-align: left;\n}\n\np {\n    text-align: left;\n}\n\n.inner-content {\n    padding: 16px;\n}\n\n.task-intervent-type-list {\n    margin-bottom: 32px;\n\n    .task-intervent-type-list-label {\n        display: block;\n        margin-bottom: 8px;\n        color: #000000;\n        font-size: 8px;\n        font-weight: bold;\n        line-height: 10px;\n        text-transform: uppercase;\n    }\n}\n\n.task-intervent-type-row {\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    height: 80px;\n    margin: 0 0 16px;\n    padding: 16px;\n    border: 1px solid #DDDDDD;\n    border-radius: 5px;\n    background-color: #FFFFFF;\n}\n\n.task-intervent-type-code {\n    flex-basis: 48px;\n    flex-grow: 0;\n}\n\n.task-intervent-type-label {\n    flex-grow: 1;\n    margin: 0 16px;\n    color: #DDDDDD;\n    font-size: 16px;\n    font-weight: bold;\n\n    &.intervent-type {\n        color: #000000;\n\n        // text-transform: capitalize;\n\n    }\n}\n\n.task-intervent-type-radio {\n    flex-basis: 32px;\n    flex-grow: 0;\n\n    > ion-icon {\n        color: #DDDDDD;\n        font-size: 32px;\n    }\n\n    &.checked {\n        > ion-icon {\n            color: #0A6FDB;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
