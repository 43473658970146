import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Section } from 'src/app/models/section/Section';

export const loadSections = createAction('[Section/API] Load Sections', props<{ sections: Section[] }>());
export const setSections = createAction('[Section/API] Set Sections', props<{ sections: Section[] }>());
export const addSection = createAction('[Section/API] Add Section', props<{ section: Section }>());
export const setSection = createAction('[Section/API] Set Section', props<{ section: Section }>());
export const upsertSection = createAction('[Section/API] Upsert Section', props<{ section: Section }>());
export const addSections = createAction('[Section/API] Add Sections', props<{ sections: Section[] }>());
export const upsertSections = createAction('[Section/API] Upsert Sections', props<{ sections: Section[] }>());
export const updateSection = createAction('[Section/API] Update Section', props<{ update: Update<Section> }>());
export const updateSections = createAction('[Section/API] Update Sections', props<{ updates: Update<Section>[] }>());
export const mapSection = createAction('[Section/API] Map Section', props<{ entityMap: EntityMapOne<Section> }>());
export const mapSections = createAction('[Section/API] Map Sections', props<{ entityMap: EntityMap<Section> }>());
export const deleteSection = createAction('[Section/API] Delete Section', props<{ id: string }>());
export const deleteSections = createAction('[Section/API] Delete Sections', props<{ ids: string[] }>());
// eslint-disable-next-line max-len
export const deleteSectionsByPredicate = createAction('[Section/API] Delete Sections By Predicate', props<{ predicate: Predicate<Section> }>());
export const clearSections = createAction('[Section/API] Clear Sections');

/**
 * The sync action
 */
export const syncSections = createAction('[Sections/API] Sync', props<{ projectId: string }>());

/**
 * The syncSections success action
 */
export const syncSectionsSuccess = createAction('[Sections/API] Sync success', props<{ sections: Section[] }>());

/**
 * The syncSections fail action
 */
export const syncSectionsFail = createAction('[Sections/API] Sync fail', props<{ error: any }>());
