import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Image } from 'src/app/models/image/Image';

/**
 * The interface of the images state
 */
export interface ImagesState extends EntityState<Image> {
  error: any;
  isSyncing: boolean;
  isSynced: boolean;
}

export const selectImageId = ((a: Image): string => a.id);

export const imagesAdapter: EntityAdapter<Image> = createEntityAdapter<Image>({
  selectId: selectImageId,
});

export const getImagesState = createFeatureSelector<ImagesState>('images');
export const {
  selectIds: imageIds,
  selectEntities: imageEntities,
  selectAll: images,
  selectTotal: totalImages
} = imagesAdapter.getSelectors(getImagesState);

// select the array of image ids
export const selectImageIds = imageIds;

// select the dictionary of image entities
export const selectImageEntities = imageEntities;

// select the array of images
export const selectAllImages = images;

// select the total image count
export const selectImageTotal = totalImages;

export const selectImage = (id: string) => createSelector(
  imageEntities,
  entities => entities[id]
);

export const selectImagesByID = (ids: string[]) => createSelector(
  imageEntities,
  entities => ids.map(id => entities[id])
);
