import { Task } from '../task/Task';

/**
 * The Project model
 */
export class Project {
  /**
   * The unique identifier of the project
   */
  id: string;
  /**
   * The name used as title of the project
   */
  name: string;
  /**
   * A short description of the project
   */
  description?: string;
  /**
   * The status of the project
   */
  status: string;
  /**
   * The list of local draft tasks
   */
  drafts?: Task[];
  /**
   * The list of sent tasks
   */
  sents?: Task[];
  boatId: string;
  boatImageId?: any;
  coverImageUrl?: string;

  defaultSectionId?: string;
  defaultMapCenter?: number[];
  defaultMapZoom?: number;
  defaultMapBounds?: any;
  sections: string;
}
