import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InterventType } from 'src/app/models/interventType/InterventType';

/**
 * The interface of the intervent types state
 */
export interface InterventTypesState extends EntityState<InterventType> {
  error: any;
  isSyncing: boolean;
  isSynced: boolean;
}

export const selectInterventTypeId = ((a: InterventType): string => a.id);

export const sortByName = (a: InterventType, b: InterventType): number => a.name.localeCompare(b.name);

export const interventTypesAdapter: EntityAdapter<InterventType> = createEntityAdapter<InterventType>({
  selectId: selectInterventTypeId,
  sortComparer: sortByName,
});

export const getInterventTypesState = createFeatureSelector<InterventTypesState>('interventTypes');
export const {
  selectIds: interventTypeIds,
  selectEntities: interventTypeEntities,
  selectAll: interventTypes,
  selectTotal: totalInterventTypes
} = interventTypesAdapter.getSelectors(getInterventTypesState);

// select the array of section ids
export const selectInterventTypeIds = interventTypeIds;

// select the dictionary of interventType entities
export const selectInterventTypeEntities = interventTypeEntities;

// select the array of interventTypes
export const selectAllInterventTypes = interventTypes;

// select the total interventType count
export const selectInterventTypeTotal = totalInterventTypes;

export const selectInterventType = (id: string) => createSelector(
  interventTypeEntities,
  entities => entities[id]
);

export const selectInterventTypesByID = (ids: string[]) => createSelector(
  interventTypeEntities,
  entities => ids.map(id => entities[id])
);
