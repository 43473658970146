import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Task } from 'src/app/models/task/Task';

/**
 * The interface of the drafts state
 */
export interface DraftsState extends EntityState<Task> {
  error: any;
  isSending: boolean;
  isSended: boolean;
}

export const selectDraftId = ((a: Task): string => a.id);

export const sortByName = (a: Task, b: Task): number => a.name.localeCompare(b.name);

export const draftsAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: selectDraftId,
  sortComparer: sortByName,
});

export const getDraftsState = createFeatureSelector<DraftsState>('drafts');
export const {
  selectIds: draftIds,
  selectEntities: draftEntities,
  selectAll: drafts,
  selectTotal: totalDrafts
} = draftsAdapter.getSelectors(getDraftsState);

// select the array of draft ids
export const selectDraftIds = draftIds;

// select the dictionary of draft entities
export const selectDraftEntities = draftEntities;

// select the array of drafts
export const selectAllDrafts = drafts;

// select the total draft count
export const selectDraftTotal = totalDrafts;

export const selectDraft = (id: string) => createSelector(
  draftEntities,
  entities => entities[id]
);

export const selectDraftsByID = (ids: string[]) => createSelector(
  draftEntities,
  entities => ids.map(id => entities[id])
);

export const selectAllDraftsByProjectID = (projectId: string) => createSelector(
  selectAllDrafts,
  (allDrafts) => allDrafts.filter(draft => ('' + draft.projectId) === ('' + projectId))
);
