import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Task } from 'src/app/models/task/Task';

/**
 * The interface of the tasks state
 */
export interface TasksState extends EntityState<Task> {
  error: any;
  isSyncing: boolean;
  isSynced: boolean;
}

export const selectTaskId = ((a: Task): string => a.id);

export const sortByStatus = (a: Task, b: Task): number => a.status.localeCompare(b.status);

export const tasksAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: selectTaskId,
  sortComparer: sortByStatus,
});

export const getTasksState = createFeatureSelector<TasksState>('tasks');
export const {
  selectIds: taskIds,
  selectEntities: taskEntities,
  selectAll: tasks,
  selectTotal: totalTasks
} = tasksAdapter.getSelectors(getTasksState);

// select the array of task ids
export const selectTaskIds = taskIds;

// select the dictionary of task entities
export const selectTaskEntities = taskEntities;

// select the array of tasks
export const selectAllTasks = tasks;

// select the total task count
export const selectTaskTotal = totalTasks;

export const selectTask = (id: string) => createSelector(
  taskEntities,
  entities => entities[id]
);

export const selectTasksByID = (ids: string[]) => createSelector(
  taskEntities,
  entities => ids.map(id => entities[id])
);

export const selectAllTasksByProjectID = (id: string) => createSelector(
  selectAllTasks,
  (allTasks) => allTasks.filter(task => (('' + task.projectId) === ('' + id)))
);
