import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
// eslint-disable-next-line max-len
import { addDraft, deleteDraft, deleteDrafts, newDraft, newDraftSuccess, sendDraft, sendDraftFail, sendDrafts, sendDraftsFail, sendDraftsSuccess, sendDraftSuccess } from './drafts.actions';
import { DataService } from 'src/app/services/data/data.service';
import { Task } from 'src/app/models/task/Task';
import { Image } from 'src/app/models/image/Image';

@Injectable()
export class DraftsEffects {

  newDraft$ = createEffect(() => this.actions$.pipe(
    ofType(newDraft),
    switchMap((payload: { task: Task }) => this.dataService.addDraft(payload.task).pipe(
      map(() => newDraftSuccess({ task: payload.task })),
      catchError(error => of(sendDraftFail({ error })))
    ))
  ));

  newDraftSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(newDraftSuccess),
    map((payload: { task: Task }) => addDraft({ task: payload.task }))
  ));

  sendDraft$ = createEffect(() => this.actions$.pipe(
    ofType(sendDraft),
    switchMap((payload: { id: string }) => this.dataService.sendDraft(payload.id).pipe(
      map(() => sendDraftSuccess({ id: payload.id })),
      catchError(error => of(sendDraftFail({ error })))
    ))
  ));

  sendDraftSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(sendDraftSuccess),
    map((payload: { id: string }) => deleteDraft({ id: payload.id }))
  ));

  sendDrafts$ = createEffect(() => this.actions$.pipe(
    ofType(sendDrafts),
    switchMap((payload: { ids: string[] }) => this.dataService.sendDrafts(payload.ids).pipe(
      map(() => sendDraftsSuccess({ ids: payload.ids })),
      catchError(error => of(sendDraftsFail({ error })))
    ))
  ));

  sendDraftsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(sendDraftsSuccess),
    map((payload: { ids: string[] }) => deleteDrafts({ ids: payload.ids }))
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
