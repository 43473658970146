import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Section } from 'src/app/models/section/Section';
import { DataService } from 'src/app/services/data/data.service';
import { loadSections, syncSections, syncSectionsFail, syncSectionsSuccess, upsertSections } from './sections.actions';

@Injectable()
export class SectionsEffects {

  syncProjectSections$ = createEffect(() => this.actions$.pipe(
    ofType(syncSections),
    switchMap((payload: { projectId: string }) => this.dataService.getProjectSections(payload.projectId).pipe(
      map((sections) => syncSectionsSuccess({ sections })),
      catchError(error => of(syncSectionsFail({ error })))
    ))
  ));

  syncSectionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(syncSectionsSuccess),
    map((payload: { sections: Section[] }) => upsertSections(payload))
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
