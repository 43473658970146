import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/states/AppState';
import { Observable, of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

/**
 * JsonAPI filter interface
 */
export interface Filter {
  /**
   * The name of the filter
   */
  name: string;
  /**
   * The value of the filter
   */
  value: any;
}

/**
 * JsonAPI Service
 */
@Injectable({
  providedIn: 'root'
})
export class JsonApiService {
  /**
   * @ignore
   */
  apiBase: string = environment.data.baseUrl + '/' + environment.data.apiVersion;

  /**
   * @ignore
   *
   * @param injector
   * @param authService
   */
  constructor(
    private store: Store<AppState>,
    private injector: Injector,
  ) { }

  getInstance(): Observable<string> {
    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn && loginState.instance) {
          return of(loginState.instance);
        }
        return of('');
      })
    );
  }

  public buildApiBase() {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance().pipe(take(1)).subscribe((instance) => {
          let apiBase = environment.data.baseUrl + '/' + environment.data.apiVersion;
          if (instance && instance.length) {
            apiBase = '';
            const instanceParams = environment.multiInstance[instance];
            if (instanceParams) {
              apiBase = instanceParams.data.baseUrl + '/' + instanceParams.data.apiVersion;
            }
          }
          resolve(apiBase);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  getToken(): Observable<string> {
    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn && loginState.user && loginState.user.token && loginState.user.token.accessToken) {
          return of(loginState.user.token.accessToken);
        }
        return of('');
      })
    );
  }

  public buildJsonApiHeaders() {
    return new Promise<HttpHeaders>((resolve, reject) => {
      try {
        this.getToken().pipe(take(1)).subscribe((token) => {
          const header = new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/vnd.api+json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Accept: 'application/vnd.api+json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: 'Bearer ' + token
          });
          resolve(header);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public buildJsonApiImageHeaders() {
    return new Promise<HttpHeaders>((resolve, reject) => {
      try {
        this.getToken().pipe(take(1)).subscribe((token) => {
          const header = new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            // 'Content-Type': 'application/vnd.api+json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            // Accept: 'application/vnd.api+json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: 'Bearer ' + token,
            responseType: 'arraybuffer',
            observe: 'response'
          });
          resolve(header);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public buildJsonApiFileHeaders(): HttpHeaders {
    const header = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/pdf',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Disposition': 'attachment; filename=report.pdf',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/vnd.api+json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: 'Bearer ' + this.getToken()
    });
    return header;
  }

  /**
   * Get collection with pagination
   *
   * @param resource
   * @param page
   * @param pageSize
   * @param filters
   * @param orderby
   * @returns
   */
  public async getCollectionPaginated(
    resource: string,
    page: number = 1,
    pageSize: number = 20,
    filters?: Array<Filter>,
    orderby?: string
  ) {
    const serviceHeaders = this.buildJsonApiFileHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    payload = payload.append('page[number]', page.toString());
    payload = payload.append('page[size]', pageSize.toString());

    const options = { headers: serviceHeaders, params: payload };
    const apiBase = await this.buildApiBase();
    return this.injector.get(HttpClient).get(`${apiBase}/${resource}`, options);
  }

  /**
   * Customizable GET request
   *
   * @param $route
   * @returns
   */
  public async customGetRequest(route: string) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const payload = new HttpParams();
    const options = { headers: serviceHeaders, params: payload };
    const apiBase = await this.buildApiBase();
    return this.injector.get(HttpClient).get(`${apiBase}/${route}`, options);
  }

  /**
   * Customizable GET request
   *
   * @param $route
   * @returns
   */
  public async customFullRouteGetRequest(route: string) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const payload = new HttpParams();
    const options = { headers: serviceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${route}`, options);
  }

  /**
   * Customizable GET request with a blob response type
   *
   * @param $route
   * @returns
   */
  public async customGetImageRequest($route) {
    const serviceHeaders = await this.buildJsonApiImageHeaders();
    const payload = new HttpParams();
    const options = { headers: serviceHeaders, params: payload };
    const apiBase = await this.buildApiBase();
    return this.injector.get(
      HttpClient).get(`${apiBase}/${$route}`, options);
  }

  /**
   * Customizable GET request with a blob response type
   *
   * @param $route
   * @returns
   */
  public async customGetFileRequest($route) {
    const serviceHeaders = this.buildJsonApiFileHeaders();
    const payload = new HttpParams();
    // const options = { headers: serviceHeaders, responseType: 'blob', params: payload };
    const apiBase = await this.buildApiBase();
    return this.injector.get(
      HttpClient).get(`${apiBase}/${$route}`, { headers: serviceHeaders, responseType: 'blob', params: payload }
      );
  }

  /**
   * Get collection
   *
   * @param resource
   * @param filters
   * @param orderby
   * @returns
   */
  public async getCollection(resource: string, filters?: Array<Filter>, orderby?: string) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    const options = { headers: serviceHeaders, params: payload };
    const apiBase = await this.buildApiBase();
    return this.injector.get(HttpClient).get(`${apiBase}/${resource}`, options);
  }

  /**
   * Get collection
   *
   * @param resource
   * @param filters
   * @param orderby
   * @returns
   */
  // eslint-disable-next-line max-len
  public async getRelatedCollection(resource: string, resourceId: string, relatedResource: string, filters?: Array<Filter>, orderby?: string) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    const options = { headers: serviceHeaders, params: payload };
    const apiBase = await this.buildApiBase();
    return this.injector.get(HttpClient).get(`${apiBase}/${resource}/${resourceId}/${relatedResource}`, options);
  }

  /**
   * Get entity
   *
   * @param resource
   * @param id
   * @returns
   */
  public async getEntity(resource: string, id) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const apiBase = await this.buildApiBase();
    return this.injector.get(HttpClient).get(`${apiBase}/${resource}/${id}`, options);
  }

  /**
   * Update entity
   *
   * @param resource
   * @param id
   * @param data
   * @returns
   */
  public async updateEntity(resource: string, id, data) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const apiBase = await this.buildApiBase();
    return this.injector.get(HttpClient).patch(`${apiBase}/${resource}/${id}`, data, options);
  }

  /**
   * Update entity with custom path
   *
   * @param data
   * @param path
   * @returns
   */
  public async updateEntityCustomPath(data, path?: string) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const apiBase = await this.buildApiBase();
    const uri = `${apiBase}/${path}`;
    return this.injector.get(HttpClient).patch(uri, data, options);
  }

  /**
   * Post entity
   *
   * @param resource
   * @param data
   * @param addictionalPath
   * @returns
   */
  public async postEntity(resource: string, data, addictionalPath?: string) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const apiBase = await this.buildApiBase();
    let uri = '';
    if (addictionalPath) {
      uri = `${apiBase}/${resource}/${addictionalPath}`;
    } else {
      uri = `${apiBase}/${resource}`;
    }
    return this.injector.get(HttpClient).post(uri, data, options);
  }

  /**
   * Post entity with custom path
   *
   * @param data
   * @param path
   * @returns
   */
  public async postEntityCustomPath(data, path?: string) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const apiBase = await this.buildApiBase();
    const uri = `${apiBase}/${path}`;
    return this.injector.get(HttpClient).post(uri, data, options);
  }

  /**
   * Delete entity
   *
   * @param resource
   * @param id
   * @returns
   */
  public async deleteEntity(resource: string, id) {
    const serviceHeaders = await this.buildJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const apiBase = await this.buildApiBase();
    const uri = `${apiBase}/${resource}/${id}`;
    return this.injector.get(HttpClient).delete(uri, options);
  }
}
