import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Task } from 'src/app/models/task/Task';

export const loadDrafts = createAction('[Draft/API] Load Drafts', props<{ tasks: Task[] }>());
export const setDrafts = createAction('[Draft/API] Set Drafts', props<{ tasks: Task[] }>());
export const addDraft = createAction('[Draft/API] Add Draft', props<{ task: Task }>());
export const setDraft = createAction('[Draft/API] Set Draft', props<{ task: Task }>());
export const upsertDraft = createAction('[Draft/API] Upsert Draft', props<{ task: Task }>());
export const addDrafts = createAction('[Draft/API] Add Drafts', props<{ tasks: Task[] }>());
export const upsertDrafts = createAction('[Draft/API] Upsert Drafts', props<{ tasks: Task[] }>());
export const updateDraft = createAction('[Draft/API] Update Draft', props<{ update: Update<Task> }>());
export const updateDrafts = createAction('[Draft/API] Update Drafts', props<{ updates: Update<Task>[] }>());
export const mapDraft = createAction('[Draft/API] Map Draft', props<{ entityMap: EntityMapOne<Task> }>());
export const mapDrafts = createAction('[Draft/API] Map Drafts', props<{ entityMap: EntityMap<Task> }>());
export const deleteDraft = createAction('[Draft/API] Delete Draft', props<{ id: string }>());
export const deleteDrafts = createAction('[Draft/API] Delete Drafts', props<{ ids: string[] }>());
export const deleteDraftsByPredicate = createAction('[Draft/API] Delete Drafts By Predicate', props<{ predicate: Predicate<Task> }>());
export const clearDrafts = createAction('[Draft/API] Clear Drafts');

export const newDraft = createAction('[Draft/Page] New Draft', props<{ task: Task }>());
export const newDraftSuccess = createAction('[Draft/Page] New Draft Success', props<{ task: Task }>());
export const newDraftFail = createAction('[Draft/Page] New Draft Fail', props<{ error: any }>());

export const sendDraft = createAction('[Draft/Page] Send Draft', props<{ id: string }>());
export const sendDraftSuccess = createAction('[Draft/Page] Send Draft Success', props<{ id: string }>());
export const sendDraftFail = createAction('[Draft/Page] Send Draft Fail', props<{ error: any }>());

export const sendDrafts = createAction('[Draft/Page] Send Drafts', props<{ ids: string[] }>());
export const sendDraftsSuccess = createAction('[Draft/Page] Send Drafts Success', props<{ ids: string[] }>());
export const sendDraftsFail = createAction('[Draft/Page] Send Drafts Fail', props<{ error: any }>());
