import { Token } from '../token/Token';

/**
 * The User model
 */
export class User {
  /**
   * The email used as credential to login
   */
  email: string;
  /**
   * The unique identifier of the user
   */
  id: string;
  /**
   * The token of the current session
   */
  token: Token;
}
