import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/states/AppState';
import { connectionChange } from 'src/app/states/network/network.actions';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(
    private platform: Platform,
    private store: Store<AppState>
  ) {
    this.platform.ready().then(() => {
      this.initializeNetwork();
    });
  }

  initializeNetwork() {
    Network.getStatus().then(status => {
      this.store.dispatch(connectionChange({
        connectionType: status.connectionType,
      }));
    });
    Network.addListener('networkStatusChange', status => {
      this.store.dispatch(connectionChange({
        connectionType: status.connectionType,
      }));
    });
  }
}
