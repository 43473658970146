import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Image } from 'src/app/models/image/Image';

export const loadImages = createAction('[Image/Entity] Load Images', props<{ images: Image[] }>());
export const setImages = createAction('[Image/Entity] Set Images', props<{ images: Image[] }>());
export const addImage = createAction('[Image/Entity] Add Image', props<{ image: Image }>());
export const setImage = createAction('[Image/Entity] Set Image', props<{ image: Image }>());
export const upsertImage = createAction('[Image/Entity] Upsert Image', props<{ image: Image }>());
export const addImages = createAction('[Image/Entity] Add Images', props<{ images: Image[] }>());
export const upsertImages = createAction('[Image/Entity] Upsert Images', props<{ images: Image[] }>());
export const updateImage = createAction('[Image/Entity] Update Image', props<{ update: Update<Image> }>());
export const updateImages = createAction('[Image/Entity] Update Images', props<{ updates: Update<Image>[] }>());
export const mapImage = createAction('[Image/Entity] Map Image', props<{ entityMap: EntityMapOne<Image> }>());
export const mapImages = createAction('[Image/Entity] Map Images', props<{ entityMap: EntityMap<Image> }>());
export const deleteImage = createAction('[Image/Entity] Delete Image', props<{ id: string }>());
export const deleteImages = createAction('[Image/Entity] Delete Images', props<{ ids: string[] }>());
// eslint-disable-next-line max-len
export const deleteImagesByPredicate = createAction('[Image/Entity] Delete Images By Predicate', props<{ predicate: Predicate<Image> }>());
export const clearImages = createAction('[Image/Entity] Clear Images');

/**
 * The sync action
 */
export const syncImage = createAction('[Images/API] Sync', props<{ imageId: string; imageSize: string }>());

/**
 * The syncImage success action
 */
export const syncImageSuccess = createAction('[Images/API] Sync success', props<{ image: Image }>());

/**
 * The syncImage fail action
 */
export const syncImageFail = createAction('[Images/API] Sync fail', props<{ error: any }>());
