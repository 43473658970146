import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Section } from 'src/app/models/section/Section';

/**
 * The interface of the sections state
 */
export interface SectionsState extends EntityState<Section> {
  error: any;
  isSyncing: boolean;
  isSynced: boolean;
}

export const selectSectionId = ((a: Section): string => a.id);

export const sortByName = (a: Section, b: Section): number => a.name.localeCompare(b.name);

export const sectionsAdapter: EntityAdapter<Section> = createEntityAdapter<Section>({
  selectId: selectSectionId,
  sortComparer: sortByName,
});

export const getSectionsState = createFeatureSelector<SectionsState>('sections');
export const {
  selectIds: sectionIds,
  selectEntities: sectionEntities,
  selectAll: sections,
  selectTotal: totalSections
} = sectionsAdapter.getSelectors(getSectionsState);

// select the array of section ids
export const selectSectionIds = sectionIds;

// select the dictionary of section entities
export const selectSectionEntities = sectionEntities;

// select the array of sections
export const selectAllSections = sections;

// select the total section count
export const selectSectionTotal = totalSections;

export const selectSection = (id: string) => createSelector(
  sectionEntities,
  entities => entities[id]
);

export const selectSectionsByID = (ids: string[]) => createSelector(
  sectionEntities,
  entities => ids.map(id => entities[id])
);

export const selectAllSectionsByProjectID = (projectId: string) => createSelector(
  selectAllSections,
  (allSections) => allSections.filter(section => ('' + section.projectId) === ('' + projectId))
);

export const selectAllSectionsByBoatID = (id: string) => createSelector(
  selectAllSections,
  (allSections) => allSections.filter(section => section.boatId === id)
);
